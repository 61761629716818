<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="onSubmit"
    >
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-text-field
                v-model="accountModel.firstName"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="First Name"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>First Name
                </template>
              </v-text-field>

              <v-text-field
                v-model="accountModel.lastName"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Last Name"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Last Name
                </template>
              </v-text-field>

              <v-text-field
                v-model="accountModel.personalId"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Personal ID"
                hide-details="auto"
                class="mt-3"
                label="Personal ID"
              >
              </v-text-field>

              <v-text-field
                v-model="accountModel.email"
                :rules="[validators.required, validators.emailValidator]"
                outlined
                dense
                type="email"
                placeholder="E-mail"
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>E-mail
                </template>
              </v-text-field>

              <v-text-field
                v-model="accountModel.phone"
                :rules="[validators.required]"
                outlined
                dense
                type="phone"
                placeholder="Phone"
                label="Phone"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-select
                v-model="accountModel.country"
                :loading="loading"
                :rules="[validators.required]"
                placeholder="Country"
                :items="countryFilterItems"
                item-text="text"
                item-value="value"
                outlined
                dense
                clearable
                hide-details="auto"
                class="mt-3"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Country
                </template>
              </v-select>

              <v-text-field
                v-model="accountModel.address"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Address"
                label="Address"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-if="accountModel.country == 'BR'"
                v-model="accountModel.number"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Number"
                label="Number"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-model="accountModel.city"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="City"
                label="City"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-if="accountModel.country == 'BR'"
                v-model="accountModel.state"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="State"
                label="State"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-if="accountModel.country == 'BR'"
                v-model="accountModel.neighbourhood"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Neighbourhood"
                label="Neighbourhood"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-model="accountModel.postcode"
                :rules="[validators.required]"
                outlined
                dense
                placeholder="Postcode"
                label="Postcode"
                hide-details="auto"
                class="mt-3"
              >
              </v-text-field>

              <v-text-field
                v-model="accountModel.description"
                :rules="[validators.required]"
                outlined
                dense
                label="Description"
                placeholder="Description"
                hide-details="auto"
                class="mt-3"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            type="submit"
            class="me-3 mt-3"
            :disabled="!valid"
            :loading="loading"
          >
            Update
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import {
  onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import { mdiClose, mdiKeyOutline, mdiHelpCircleOutline } from '@mdi/js'
import {
  required,
  emailValidator,
  decimalValidator,
} from '@core/utils/validation'
import store from '@/store'
import storeModule from '@/views/account/storeModule'

export default {
  setup() {
    const STORE_MODULE_NAME = 'processing-payment-links'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)
    const accountModel = ref({})
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const countryFilterItems = ref([])

    const onSubmit = () => {
      if (valid.value) {
        localStorage.setItem('account', JSON.stringify(accountModel.value))
      } else {
        validate()
      }
    }

    const fetchConfig = () => {
      loading.value = true
      store
        .dispatch('processing-payment-links/fetchConfig')
        .then(response => {
          const config = response.data
          countryFilterItems.value = config.countries
        })
        .catch(error => error)
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchConfig()

      if (localStorage.getItem('account')) {
        accountModel.value = JSON.parse(localStorage.getItem('account'))
      }
    })

    return {
      loading,
      countryFilterItems,
      valid,
      accountModel,
      onSubmit,
      resetForm,
      form,

      // validation
      validators: { required, emailValidator, decimalValidator },
      icons: {
        mdiClose,
        mdiKeyOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

  <style lang="scss" scoped>
  .two-factor-auth {
    max-width: 25rem;
  }

  .security-character {
    position: absolute;
    bottom: -0.5rem;
  }

  .red--text{
    color: red;
  }
  </style>
